import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useBlogCategoryList() {
    const toast = useToast()
    const refListTable = ref(null)
    const tableColumns = [
        { key: 'id', label: '#', sortable: true },
        { key: 'name',label: 'Name', sortable: false },
        { key: 'uri',label:'uri', sortable: true },
        { key: 'is_active',label:'Active', sortable: false },
    
        { key: 'actions' },
        
    ]
    const perPage = ref(10)
    const totals = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
   
    const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totals.value,
        }
    })
    const refetchData = () => {
    
        refListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery], () => {
        console.log('change');
        refetchData()
    })
    const fetchData = (ctx, callback)=>{
        
        store
        .dispatch('app-blog-category/fetchData', {
          limit:10,
          offset:0,
        })
        .then(response => {
          
         
          const categories = response.data.data;
          console.log('categories',response.data);
        //  const { invoices, total } = response.data
         callback(categories)
         // callback(invoices)
          //totalInvoices.value = total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching invoices' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        
      }
      const deleteData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-blog-category/fetchDelete',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete Blog Category",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }
    return {
      fetchData,
      
      tableColumns,
      perPage,
      currentPage,
      totals,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      deleteData,
      refetchData,
    }
}